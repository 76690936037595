import React, {useState} from "react";
import Loader from "./Loader";
import './styles.css';
import { Helmet } from 'react-helmet';

function PlateRecognition() {
  const [image, setImage] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [plateText, setPlateText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    if (!image) {
      alert("Please upload an image");
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", image);

    try {
      const response = await fetch("https://api.mloomer.com/get-plate", {
        method: "POST",
        body: image,
        headers: {
          "Content-Type": "image/jpeg",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const plateInfo = await response.json();

      if (!plateInfo.text) {
        setPlateText("No Plate Found")
        setResultImage(null)
      } else {
        setResultImage(`data:image/jpeg;base64,${plateInfo.plate}`);
        setPlateText(plateInfo.text.join(", ")); // Join the detected text into a string
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (

  <div>
    <Helmet>
      <title>LPR Recognition</title>
      <link rel="icon" type="image/x-icon" href="./favicon.ico" />
    </Helmet>
        <h2>License Plate Recognition</h2>

        {/* File upload form */}
        <form onSubmit={handleSubmit}>
          <input type="file" accept="image/*" onChange={handleImageChange} />
          <div><button type="submit" disabled={isLoading}>
            {isLoading ? <Loader /> : "Upload and Detect"}
          </button></div>
        </form>

        {plateText && (
            <div>
              <h3>Detected Plate:</h3>
              <p>{plateText}</p>
              {resultImage && (<img src={resultImage} alt="Detected Plate" />)}
            </div>
        )}
      </div>
  );
}



export default PlateRecognition;
